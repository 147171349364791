<template>

    <div class="app flex-row align-items-center" id="particles-js">

        <!--MainPage-->
        <span v-if="images.length <= 0"/>
        <img v-else :src='url(images[currentIndex])'>

    </div>

</template>

<script>
    import iss from '@/services/iss.js'
    import constellation from '@/services/constellation';
    import blackhole from '@/services/blackhole';
    import 'particles.js';

    export default {
        name: "Slider",
        props: {
            conId: {
                type: String,
                default: null
            },
            devId: {
                type: String,
                default: null
            },
        },

        data() {
            return {
                go: true,
                tempImages: [],
                images: [],
                currentIndex: null,
                contellation: '',
                time: 5,
            };
        },

        created() {
            this.getImages();
        },

        methods: {
            initParticles() {
                window.particlesJS("particles-js", {
                    "particles": {
                        "number": {
                            "value": 80,
                            "density": {
                                "enable": true,
                                "value_area": 700
                            }
                        },
                        "color": {
                            "value": "#ffffff"
                        },
                        "shape": {
                            "type": "circle",
                            "stroke": {
                                "width": 0,
                                "color": "#000000"
                            },
                            "polygon": {
                                "nb_sides": 5
                            },
                        },
                        "opacity": {
                            "value": 0.5,
                            "random": false,
                            "anim": {
                                "enable": false,
                                "speed": 1,
                                "opacity_min": 0.1,
                                "sync": false
                            }
                        },
                        "size": {
                            "value": 5,
                            "random": true,
                            "anim": {
                                "enable": false,
                                "speed": 40,
                                "size_min": 0.1,
                                "sync": false
                            }
                        },
                        "line_linked": {
                            "enable": true,
                            "distance": 150,
                            "color": "#ffffff",
                            "opacity": 0.4,
                            "width": 1
                        },
                        "move": {
                            "enable": true,
                            "speed": 6,
                            "direction": "none",
                            "random": false,
                            "straight": false,
                            "out_mode": "out",
                            "bounce": false,
                            "attract": {
                                "enable": false,
                                "rotateX": 600,
                                "rotateY": 1200
                            }
                        }
                    },
                    "interactivity": {
                        "detect_on": "canvas",
                        "events": {
                            "onhover": {
                                "enable": false,
                                "mode": "grab"
                            },
                            "onclick": {
                                "enable": true,
                                "mode": "push"
                            },
                            "resize": true
                        },
                        "modes": {
                            "grab": {
                                "distance": 140,
                                "line_linked": {
                                    "opacity": 1
                                }
                            },
                            "bubble": {
                                "distance": 400,
                                "size": 40,
                                "duration": 2,
                                "opacity": 8,
                                "speed": 3
                            },
                            "repulse": {
                                "distance": 200,
                                "duration": 0.4
                            },
                            "push": {
                                "particles_nb": 4
                            },
                            "remove": {
                                "particles_nb": 3
                            }
                        }
                    },
                    "retina_detect": false
                })
            },
            getImages() {
                iss.getConstellation(this.conId).then(response => {
                    let constellationURL = response.data.url + ":" + response.data.port
                    constellation.readDIS(constellationURL, this.devId).then(response1 => {
                        if (response1.data !== "") {
                            constellation.readDISImages(constellationURL).then(response2 => {
                                this.clean(response1.data, constellationURL, response2.data);
                            });
                        } else {
                            this.currentIndex = null;
                            this.images = [];
                            this.tempImages = [];
                            this.startSlide(response1.data.speed);
                        }
                    })
                })
            },
            url(image) {
                if (image !== undefined) {
                    return blackhole.readImage(image);
                }
            },
            arraysEqual(arr1, arr2) {
                if (arr1.length !== arr2.length)
                    return false;
                for (let i = arr1.length; i--;) {
                    if (arr1[i] !== arr2[i])
                        return false;
                }
                return true;
            },
            async clean(device, url, conImg) {
                let checkedImages = [];
                await new Promise(async r => {
                    if (device.images.length > 0) {
                        device.images.forEach(async function (disImage) {
                            await new Promise(r2 => {
                                if (conImg.length > 0) {
                                    let counter = 0;
                                    conImg.some(conImage => {
                                        if (disImage === conImage.image) {
                                            checkedImages.push(disImage);
                                        }
                                        counter++;
                                        if(counter === conImg.length) {
                                            r2("OK2");
                                        }
                                    })
                                } else {
                                    r2("OK2")
                                }
                            });
                            r("OK");
                        }.bind(this))
                    } else {
                        r("OK");
                    }
                });
                if (!this.arraysEqual(device.images, checkedImages) || device.images.length !== this.tempImages.length) {
                    constellation.updateDISDevice(url, device._id, {images: checkedImages}).then(() => {
                        this.tempImages = checkedImages;
                        this.time = device.speed;
                        if (this.go) {
                            this.startSlide(device.speed);
                        }
                    });
                } else {
                    this.tempImages = checkedImages;
                    this.time = device.speed;
                    if (this.go) {
                        await this.startSlide(device.speed);
                    }
                }
            },
            async startSlide(time) {
                this.go = false;
                this.getImages();
                if (this.images.length > 0) {
                    for (let i = 0; i < this.images.length; i++) {
                        await new Promise(r => setTimeout(r, time * 1000));
                        if (this.currentIndex === this.images.length - 1) {
                            this.currentIndex = 0
                        } else {
                            if (this.currentIndex + 1 < this.images.length) {
                                this.currentIndex += 1
                            } else {
                                this.currentIndex = 0
                            }
                        }  //bugged
                    }
                } else {
                    this.currentIndex = null;
                    await new Promise(r => setTimeout(r, time * 1000));
                }
                this.images = this.tempImages;
                await this.startSlide(this.time);
            }
        },
        mounted() {
            this.initParticles();
        },

    };

</script>


<style scoped>

    * {
        margin: 0;
        padding: 0;
    }

    html, body {
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    img {
        position: absolute;
        max-width: 100%;
        max-height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: contain;
        width: 100%;
    }

    canvas {
        position: absolute;
        z-index: 0;
    }

    .generate-merged-header .site-header {
        z-index: 1;
    }

    #particles-js {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        left: 0;
        z-index: 0;
    }

    .page-header-content {
        position: relative;
    }
</style>